    .hero{
        display: flex;
        justify-content: space-between;
    }
    .left-h{
        padding: 2rem 1.5rem;
        flex:3;
        display: flex;
        gap:2rem;
        flex-direction: column;
        /* margin-left: 45px; */
    }
    .right-h{
        flex:1;
        position: relative;
        background-color:rgb(150, 85, 211);
    }
    .the-best-ad{
        margin: 4rem 0;
        background-color: #363d42;
        border-radius: 4rem;
        width: fit-content;
        padding: 20px 13px;
        position: relative;
        text-transform: uppercase;
        color: white;
        position: relative;
        align-items: center;
        justify-content: flex-end;
        
    }
    .the-best-ad>span{
        z-index: 9;
        position: relative;
    }
    .the-best-ad>div{
        position: absolute  ;
        background-color: rgba(195, 52, 231, 0.69);
        width: 5.4rem;
        height: 80%;
        left: 8px;
        bottom: 6px;
        border-radius: 3rem;
        z-index: 3;
    }
    .hero-heading{
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        font-weight: bold;
        color: white;
        font-size: 4.5rem;   
    }
    .first-heading-text{
        font-style: oblique;
    }
    .hero-heading>div:nth-of-type(3){
        font-size: 1rem;
        font-weight: 200;
        text-transform: none;
        letter-spacing: 1px;
        width: 80%;
    }
    .figures{
       display: flex;
        gap: 2rem;
    }
    .figures>div{
        display: flex;
        flex-direction: column;
    }
    .figures>div>span:nth-of-type(1){
        color: white;
        font-size: 2rem;
    }
    .figures>div>span:nth-of-type(2){
        color: var(--gray);
        text-transform: uppercase;
    }
    .hero-buttons{
        display: flex;
        gap: 1rem;
        font-weight: normal ;
       
    }

    .hero-buttons>:nth-child(1){
        color: white;
        background-color: var(--orange);
        width: 8rem;
    }
    .hero-buttons>:nth-child(2){
        color: white;
        background-color: transparent;
        border: 2px solid orange;
        width: 8rem;
    }

    /* ------Right----------- */

    .right-h>.btn{
        position: absolute;
        right: 3rem;top: 2rem;color: black;
    }
    .heart-rate{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background-color: grey;
        width: fit-content;
        padding: 1rem;
        align-items: start;
        border-radius: 5px;
        position: absolute;
        right: 4rem;
        top: 7rem;
    }
    .heart-rate>img{
        width: 2rem ;
    }
    .heart-rate>:nth-child(2){
            color: grey;
    }
    .heart-rate>:nth-child(3){
            color:white;
            font-size: 1.5rem;
    }
    .hero-image{
        position: absolute;
        top: 10rem;
        right: 10rem;
        width: 23rem;
    }
    .hero-image-back{
        position: relative;
        top: 4rem;
        right: 15rem;
        z-index: -1;
        width: 15rem;
    }
    .calories{
        display: flex;
        gap: 2rem;
        background-color: var(--caloryCard);
        border-radius: 8px;
        padding: 1rem;
        width: fit-content;
        position: relative;
        right: 26rem;
        top: 13rem;
    }
    .calories>img{
        width: 3rem;
    }
    .calories>div{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .calories>div>:nth-child(1){
        color: var(--gray);
    }
    .calories>div>:nth-child(2){
        color: white;
        font-size: 1.6rem;
    }
    .hero-blur{
        height: 30rem;
        width: 25rem;
        bottom: 2rem;
    }
    @media screen and (max-width:700px) {
        .hero{
            flex-direction: column;
          
        }
        .hero-blur{
            width: 14rem;
        }
        .the-best-ad{
            margin-top: 0;
            font-size: small;
            align-self:center;
            transform: scale(0.9);
            justify-content: center;
        }
        .hero-heading{
            font-size: xx-large;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
        .hero-heading>div:nth-child(3){
            font-size: small;
            font-weight: 200;
            letter-spacing: 1px;
            text-align: center;
            justify-content: center;
        }
        .hero-buttons{
            justify-content: center;
            align-items: center;
        }
        .figures>div>span:nth-of-type(1){
            font-size: large;
        }
        .figures>div>span:nth-of-type(2){
            font-size: small;
        }
        .right-h{
            position: relative;
            background-color: none;
        }
        .heart-rate{
            left: 1rem;
            top: 2rem;
            display: none;
        }
        .calories{
            position:relative;
            /* display: none; */
            left: 10rem;
            top: -1rem;
        
        }
        .calories>img{
            width: 1.7rem;
        }
        .hero-image{
            position: relative;
            width: 15rem;
            left: 2rem;
           align-self: center;
            
        }
        .hero-image-back{
            width: 11rem;
            left: 12rem;
            top: 0rem;
            left: 17rem;
           
        }
        
    }