.join{
    display: flex;
    padding: 0 2rem;
    gap : 10rem;
}
.left-j{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}
.left-j>hr{
    position: absolute;
    width: 10.5rem;
    border: 2px solid rgb(137, 64, 206);
    border-radius: 20%;
    margin: -10px 0;
}
.left-j>div{
    display: flex;
    gap: 1rem;
}
.right-j{
    display: flex;
    justify-content: center;
    align-items:flex-end;
}
.email-container{
    display: flex;
    gap: 8rem;background-color: grey;
    padding: 1rem 2rem;
    width: 25rem;
    height: auto;
}
.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgrayz);
}
::placeholder{
    color: var(--lightgray);
    cursor: pointer;
    
}
.btn-j{
    background-color: rgb(137, 64, 206);
    align-items: end;
    text-decoration: none;
}
@media screen and (max-width:768px) {
    .join{
        flex-direction: column;
        gap: 1rem;

    }
    .left-j{
        font-size: x-large;
        flex-direction: column;
    }
    .right-j{
        padding: 2rem;
    }
    
}
