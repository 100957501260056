.header{
    display: flex;
    justify-content: space-between;
}
.logo{
    width: 10rem;
    height: 3rem;
}
.header-menu{
    display: flex;
    list-style: none;
    gap: 4rem;
    color: white;
    font-size: 1.2rem;
}
.header-menu>li{
    display: flex;
}
.header-menu>li:hover{
    color: orange;
    cursor:pointer;
}
@media screen and (max-width:768px) {
    .header>:nth-child(2){
        position: fixed;;
        right: 2rem;
        z-index: 99;
    }
    .header-menu{
        display: flex;
        flex-direction: column;
        background-color:grey;
        padding: 2rem;
       
    }
}