.testimonials{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}
.left-t{
    display: flex;
    flex:1 1; gap: 2rem ;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
}
.left-t:nth-child(1){
    color:rgba(195, 52, 231, 0.69);
    font-weight: bold;

}
.left-t>:nth-child(2),
.left-t>:nth-child(3){
    font-weight: bold;
    font-size: 3rem;
  
}
.left-t>:nth-child(4){
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
    color: white;
}
.right-t{
    flex: 1;
    position: relative;
}
.right-t>img{
    position: absolute;
    height: 20rem;
    width: 17rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}
.right-t>:nth-child(1){
    position: absolute;
    border: 2px solid rgba(195, 52, 231, 0.69);
    height: 20rem;
    width: 17rem;
    right : 9rem;
    top: 1.4rem;
}
.right-t>:nth-child(2){
    position: absolute;
    height: 20rem;
    width: 17rem;
    background-color: rgba(195, 52, 231, 0.69);
    right: 7rem;
    top: 3.2rem;
}
.arrows{
    display: flex;
    position: absolute;
    gap: 1rem;
    bottom: 1rem;
    left: 3rem;
}
.arrows>img{
    width: 1.5rem;

}
@media screen and (max-width:700px) {
    .testimonials{
        flex-direction: column;
    }
    .left-t:nth-child(2),
    .left-t:nth-child(3){
        font-size: xx-large;
    }
    .right-t{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
    .right-t>div{
        position: relative;
        display: none;
        
    }
 
   
   .right-t>img{
        top: 0;right: 0;position: relative;align-self: center;
    }
     .right-t>:last-child{
        /* position: relative;
        bottom: 0;left: 0;
        width: 2rem; */
        display: block;
        bottom: 0px;
        left: 0px;
        
    }
}