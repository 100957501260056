.plans-container{
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 4rem;
}
.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6rem;
   
}
.plan{
    display: flex;
    flex-direction: column;
    background-color: var(--caloryCard);
    color: white;
    gap: 2rem;
    padding: 2rem;
}
.plan:nth-child(2){
    background-color: #f08d0b;
    transform: scale(1.2);
}
.plan>svg{
    fill:rgb(25, 0, 255);
    height: 3rem;
    width: 2rem;
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.plan>:nth-child(5){
    font-size: 0.8rem;
    font-weight: bold;
}
.features{
    display: flex;
   flex-direction: column   ;
    gap: 1rem;
}
.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.blur-plan-1{
    height: 23rem;
    width: 25rem;
    left: 1rem;
    top: 0rem;
}
.blur-plan-2{
    height: 30rem;
    width: 25rem;
    right: 1rem;
    bottom: 1rem;
}
@media screen and (max-width:700px) {
    .plans{
        flex-direction: column;
    }
    .plans>:nth-child(2){
        transform: none;
    }
}